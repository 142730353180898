import React from 'react';
import Layout from '../components/layout';
import Content, { SMALL } from '../components/content';

export default () => {
  return (
    <Layout>
      <Content
        style={{
          width: 700,
          '& img': { maxWidth: '100%' },
          [SMALL]: { width: 'auto', padding: 15 }
        }}
      >
        <h1 id="terms-and-privacy">Terms and Privacy</h1>
<p><a href="#terms">Terms of Service</a><br />
<a href="#privacy-policy">Privacy Policy</a></p>
<div id="terms"/>

<h2 id="terms-of-service">Terms of Service</h2>
<div style={{fontSize: 13, marginBottom: '1.5em'}}>Updated January 11, 2019</div>

<p>By accessing Actual's website, as well as any other media form, media channel, mobile or desktop application related or otherwise connected thereto (the "Site"), you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
<h3 id="use-license">Use License</h3>
<p>Permission is granted to temporarily download one copy of the materials (information or software) on the Site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
<ul>
<li>modify or copy the materials;</li>
<li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
<li>attempt to decompile or reverse engineer any software contained on the Site;</li>
<li>remove any copyright or other proprietary notations from the materials; or</li>
<li>transfer the materials to another person or "mirror" the materials on any other server.</li>
</ul>
<p>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Shift Reset LLC at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>
<h3 id="disclaimer">Disclaimer</h3>
<p>The materials on the Site are provided on an 'as is' basis. Shift Reset LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
<p>Further, Shift Reset LLC does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on the Site or otherwise relating to such materials or on any sites linked to this site.</p>
<h3 id="limitations">Limitations</h3>
<p>In no event shall Shift Reset LLC or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the Site, even if Shift Reset LLC or a Shift Reset LLC authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
<h3 id="accuracy-of-materials">Accuracy of materials</h3>
<p>The materials appearing on the Site could include technical, typographical, or photographic errors. Shift Reset LLC does not warrant that any of the materials on its website are accurate, complete or current. Shift Reset LLC may make changes to the materials contained on its website at any time without notice. However Shift Reset LLC does not make any commitment to update the materials.</p>
<h3 id="links">Links</h3>
<p>Shift Reset LLC has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Shift Reset LLC of the site. Use of any such linked website is at the user's own risk.</p>
<h3 id="modifications">Modifications</h3>
<p>Shift Reset LLC may revise these terms of service for the Site at any time without notice. By using the Site you are agreeing to be bound by the then current version of these terms of service.</p>
<h3 id="governing-law">Governing Law</h3>
<p>These terms and conditions are governed by and construed in accordance with the laws of Virginia and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
<div style={{marginTop: 50, borderBottom: "1px solid #BCCCDC"}}/>
<div id="privacy-policy" style={{marginTop: 50}}/>

<h2 id="privacy-policy">Privacy Policy</h2>
<div style={{fontSize: 13, marginBottom: '1.5em'}}>Updated January 11, 2019</div>

<p>Your privacy is important to us. It is Shift Reset LLC's policy to respect your privacy regarding any information we may collect from you across our desktop and mobile apps ("Apps") and syncing service ("Service") we own and operate.</p>
<h3 id="information-we-collect">Information we collect</h3>
<p>We may ask for personal information, such as your email, in order identify your account.</p>
<p>When you using the Service, our servers may automatically log the your Internet Protocol (IP) address, and the time and date of your usage.</p>
<p>We use a third party service for error reporting in the Apps. When an error is reported, we may collect data about the device you’re using. This data may include the device type, operating system, unique device identifiers, and device settings. What we collect can depend on the individual settings of your device and software. We recommend checking the policies of your device manufacturer or software provider to learn what information they make available to us.</p>
<h4 id="third-party-services-we-use">Third-party services we use</h4>
<ul>
<li><a href="https://stripe.com/">Stripe</a> for payment processing</li>
<li><a href="https://www.linode.com/">Linode</a> for server hosting</li>
<li><a href="https://aws.amazon.com/ses/">Amazon SES</a> for transactional email</li>
<li><a href="https://sentry.io">Sentry</a> for error reporting</li>
</ul>
<p>We never share transaction or account data with a third-party, and only Shift Reset LLC has access to it if the Service is used. Otherwise your data exists completely on your local machine. Only personal information, such as your email, is shared in order to identify and communicate with you.</p>
<h3 id="legal-bases-for-processing--retention">Legal bases for processing &amp; retention</h3>
<p>We will process your personal information lawfully, fairly and in a transparent manner. We collect and process information about you only where we have legal bases for doing so.</p>
<p>These legal bases depend on the services you use and how you use them, meaning we collect and use your information only where:</p>
<ul>
<li>it’s necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract (for example, when we provide a service you request from us);</li>
<li>it satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to market and promote our services, and to protect our legal rights and interests;</li>
<li>you give us consent to do so for a specific purpose (for example, you might consent to us sending you our newsletter); or</li>
<li>we need to process your data to comply with a legal obligation.</li>
</ul>
<p>Where you consent to our use of information about you for a specific purpose, you have the right to change your mind at any time (but this will not affect any processing that has already taken place).</p>
<p>We don’t keep personal information for longer than is necessary. <strong>Personal information is deleted upon account deletion.</strong> During the time we store it, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification. That said, we advise that no method of electronic transmission or storage is 100% secure and cannot guarantee absolute data security.</p>
<h3 id="collection-and-use-of-information">Collection and use of information</h3>
<p>We may collect, hold, use and disclose information for the following purposes and personal information will not be further processed in a manner that is incompatible with these purposes:</p>
<ul>
<li>to enable you to access and use our Service; and for internal record keeping and administrative purposes.</li>
</ul>
<h3 id="international-transfers-of-personal-information">International transfers of personal information</h3>
<p>The personal information we collect is stored and processed in United States, or where we or our partners, affiliates and third-party providers maintain facilities. By providing us with your personal information, you consent to the disclosure to these overseas third parties.</p>
<p>We will ensure that any transfer of personal information from countries in the European Economic Area (EEA) to countries outside the EEA will be protected by appropriate safeguards, for example by using standard data protection clauses approved by the European Commission, or the use of binding corporate rules or other legally accepted means.</p>
<p>Where we transfer personal information from a non-EEA country to another country, you acknowledge that third parties in other jurisdictions may not be subject to similar data protection laws to the ones in our jurisdiction. There are risks if any such third party engages in any act or practice that would contravene the data privacy laws in our jurisdiction and this might mean that you will not be able to seek redress under our jurisdiction’s privacy laws.</p>
<h3 id="your-rights-and-controlling-your-personal-information">Your rights and controlling your personal information</h3>
<p>Choice and consent: By providing personal information to us, you consent to us collecting, holding, using and disclosing your personal information in accordance with this privacy policy. If you are under 16 years of age, you must have, and warrant to the extent permitted by law to us, that you have your parent or legal guardian’s permission to access and use the website and they (your parents or guardian) have consented to you providing us with your personal information. You do not have to provide personal information to us, however, if you do not, it may affect your use of this website or the products and/or services offered on or through it.</p>
<p>Information from third parties: If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.</p>
<p>Restrict: You may choose to restrict the collection or use of your personal information. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below. If you ask us to restrict or limit how we process your personal information, we will let you know how the restriction affects your use of our website or products and services.</p>
<p>Access and data portability: You may request details of the personal information that we hold about you. You may request a copy of the personal information we hold about you. Where possible, we will provide this information in CSV format or other easily readable machine format. You may request that we erase the personal information we hold about you at any time. You may also request that we transfer this personal information to another third party.</p>
<p>Correction: If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.</p>
<p>Notification of data breaches: We will comply laws applicable to us in respect of any data breach.</p>
<p>Complaints: If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.</p>
<p>Unsubscribe: To unsubscribe from our e-mail database or opt-out of communications (including marketing communications), please contact us using the details below or opt-out using the opt-out facilities provided in the communication.</p>
<h3 id="business-transfers">Business transfers</h3>
<p>If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may continue to use your personal information according to this policy.</p>
<h3 id="changes-to-this-policy">Changes to this policy</h3>
<p>At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable steps to let users know about changes via our website. Your continued use of this site after any changes to this policy will be regarded as acceptance of our practices around privacy and personal information.</p>
<p>If we make a significant change to this privacy policy, for example changing a lawful basis on which we process your personal information, we will ask you to re-consent to the amended privacy policy.</p>
<p>Shift Reset LLC<br />
James Long<br />
<a href="mailto:help@actualbudget.com">help@actualbudget.com</a></p>
      </Content>
    </Layout>
  );
};
